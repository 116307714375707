import { useState } from "react";

import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import Button from "react-bootstrap/Button";

function App() {
  const numVideos: Number = videos.length;
  const idx = Math.floor(Math.random() * numVideos) % numVideos;
  const [videoUrl: String, setVideoUrl] = useState(videos[idx].url);

  return (
    <Container
      fluid="md"
      style={{
        marginTop: 20,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 120, textAlign: "center", marginBottom: 10 }}>
        <p>Follow us on</p>
        <div>
          <a href="https://www.facebook.com/ekannovideos">
            <FontAwesomeIcon icon={faFacebook} size="xl" />
          </a>
          <a href="https://www.youtube.com/@ekannoMedia">
            <FontAwesomeIcon
              color="red"
              style={{ marginLeft: 10 }}
              icon={faYoutube}
              size="xl"
            />
          </a>
        </div>
      </div>
      <iframe
        style={{
          display: "block",
          height: "90vh", //  "50.625vh",
          width: "90vw",
        }}
        src={buildUrl(videoUrl)}
        title="Ekanno Media TV"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>

      <Button
        style={{
          marginTop: 40,
        }}
        variant="primary"
        onClick={() => {
          while (true) {
            const idx = Math.floor(Math.random() * numVideos) % numVideos;
            if (videos[idx].url !== videoUrl) {
              setVideoUrl(videos[idx].url);
              break;
            }
          }
        }}
      >
        Show me a random drama!
      </Button>
    </Container>
  );
}

const buildUrl = (videoId) => {
  return videoId + "&autoplay=1&amp;controls=1&rel=0";
};

type Video = {
  url: string,
  title: string,
};

const videos: Video[] = [
  {
    url: "https://www.youtube.com/embed/1t-sU8u4t14?si=01bfXVR7LOKoUgKU",
    title:
      "Anarkoli | Full Natok | Tawsif Mahbub | Tasnia Farin | Bangla Natok 2024",
  },

  {
    url: "https://www.youtube.com/embed/HjbJiFKKMwM?si=XRsB10zBMXMEXR0C",
    title:
      "Amar Thakis Tui | আমার থাকিস তুই | Yash Rohan | Tanjim Saira Totini | New Bangla Natok 2024",
  },

  {
    url: "https://www.youtube.com/embed/tOZ8OHniQec?si=7h7KrQDLYkyhOusU",
    title:
      "Shui | সুঁই | Full Natok | Farhan Ahmed Jovan | Tanjim Saiara Totini | New Bangla Natok 2024",
  },
  {
    url: "https://www.youtube.com/embed/WQm_zG2yS98?si=ulzlxID2Meupqg0P",
    title:
      "Aj Akashe Chad Nei | আজ আকাশে চাঁদ নেই | Eid Natok | Khairul Basar | Sadia Ayman | New Natok 2023",
  },
  {
    url: "https://www.youtube.com/embed/RKvM8PLnoxc?si=Ocny77-L1MxC1IeL",
    title:
      "Cheating to Fitting | চিটিং টু ফিটিং | Zaher Alvi | Faria Shahrin | New Bangla Natok 2023",
  },
  {
    url: "https://www.youtube.com/embed/b6ZQh5PfsJk?si=8JVqXZ1R9QqEUVMC",
    title:
      "BONDHON | বন্ধন | Eid Natok | Tawsif Mahbub | Keya Payel | Tarik Anam Khan | Bangla New Natok 2023",
  },
  {
    url: "https://www.youtube.com/embed/pAycB4a6H-A?si=H95dYsaeqAiDYnxB",
    title:
      "Pach Bhai Chompa | পাঁচ ভাই চম্পা | Tawsif Mahbub | Keya Payel | Miftah | Bangla Eid Natok 2021",
  },
  {
    url: "https://www.youtube.com/embed/3xzDkzrPbW8?si=99eNPQuANf-0-gif",
    title:
      "Shorbot | শরবত | Eid Natok | Tanjin Tisha | Mamunur Rashid | Deep | Shawon | New Bangla Natok 2023",
  },
  {
    url: "https://www.youtube.com/embed/PfkhM5T8l90?si=RKb2v6DPGXY73B74",
    title:
      "Prem Baburchi | প্রেম বাবুর্চি | Eid Natok | Arosh Khan | Papri | Mohon Ahmed | New Natok 2023",
  },
  {
    url: "https://www.youtube.com/embed/GuCd5XkriLs?si=L7nn9jkRzBNORdhh",
    title:
      "Je Prem Esechilo | যে প্রেম এসেছিল | Yash Rohan | Tanjim Saiyara Totini | New Bangla Natok 2023",
  },
];

export default App;
